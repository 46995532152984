import betternowImage from "../assets/get-better.png";
import { Link } from "@mui/material";

export const NotFound = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh", // Adjust height as needed for full viewport height
      }}
    >
      <h2>404 - Not Found</h2>
      <img
        src={betternowImage}
        alt="Not Found"
        style={{ width: "200px", height: "auto" }}
      />
      <p>The page you're looking for does not exist.</p>
      Back to{" "}
      <Link href="/" style={{ textDecoration: "none", color: "blue" }}>
        Landing
      </Link>
    </div>
  );
};
