import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";

import appStoreBadgeWhiteDesktop from "../assets/desktop/app-store-button-white.svg";
import appStoreBadgeWhiteMobile from "../assets/mobile/app-store-button-white.svg";
import appStoreBadgeBlackDesktop from "../assets/desktop/app-store-button-black.svg";
import appStoreBadgeBlackMobile from "../assets/mobile/app-store-button-black.svg";
import appLogoMobile from "../assets/mobile/logo.svg";
import appLogoDesktop from "../assets/desktop/logo.svg";

import availableDesktop from "../assets/desktop/icons/available.svg";
import freeDesktop from "../assets/desktop/icons/free.svg";
import guidanceDesktop from "../assets/desktop/icons/guidance.svg";
import personalizedDesktop from "../assets/desktop/icons/personalized.svg";
import waveDesktop from "../assets/desktop/wave-desktop.jpg";

import availableMobile from "../assets/mobile/icons/available.svg";
import freeMobile from "../assets/mobile/icons/free.svg";
import guidanceMobile from "../assets/mobile/icons/guidance.svg";
import personalizedMobile from "../assets/mobile/icons/personalized.svg";
import waveMobile from "../assets/mobile/wave-mobile.jpg";

import deviceKnockout from "../assets/device-knockout.svg";

import appVideo from "../assets/app-video.mp4";

import "./LandingPage.css";
import { useParams } from "react-router-dom";

const LandingPage: React.FC = () => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 1024);

  const APP_STORE_LINK = "https://apps.apple.com/app/betternow-ai/id6570579784";

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 1024);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const appStoreBadgeWhite = isMobile
    ? appStoreBadgeWhiteMobile
    : appStoreBadgeWhiteDesktop;

  const waveImage = isMobile ? waveMobile : waveDesktop;
  const appStoreBadgeBlack = isMobile
    ? appStoreBadgeBlackMobile
    : appStoreBadgeBlackDesktop;

  const availableIcon = isMobile ? availableMobile : availableDesktop;
  const freeIcon = isMobile ? freeMobile : freeDesktop;
  const guidanceIcon = isMobile ? guidanceMobile : guidanceDesktop;
  const personalizedIcon = isMobile ? personalizedMobile : personalizedDesktop;
  const appLogo = isMobile ? appLogoMobile : appLogoDesktop;

  const { ad_source } = useParams(); // Get ad_source from route params
  const urlParams = new URLSearchParams(window.location.search);
  const adSourceParam = ad_source || urlParams.get("ad_source") || "none";

  const handleAppStoreClick = (buttonType: string) => {
    ReactGA.send({
      hitType: "pageview",
      page: `appstore-link-${buttonType}`,
      title: `Redirect - ${adSourceParam}`,
    });
    window.open(APP_STORE_LINK, "_blank");
  };

  return (
    <div className="landing-page">
      {/* First Section: Hero */}
      <section className="hero">
        <div className="left-section">
          <img src={appLogo} alt="Betternow Logo" className="logo" />
          <p className="tagline">
            Your personalized coach for stress and anxiety, available 24/7
          </p>
          <img
            src={appStoreBadgeWhite}
            alt="Download on the App Store"
            className="app-store-badge"
            onClick={() => handleAppStoreClick("white")}
          />
        </div>
        <div className="right-section">
          <img
            src={deviceKnockout}
            alt="Device Knockout"
            className="device-knockout"
          />

          <video
            style={{
              borderRadius: "42.34px",
              maxWidth: "383px",
              maxHeight: "781px",
            }}
            autoPlay
            loop
            muted
            playsInline
          >
            <source src={appVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        {isMobile && (
          <section className="cta cta-mobile">
            <p>Get Started today!</p>
            <img
              src={appStoreBadgeWhite}
              alt="Download on the App Store"
              className="app-store-badge"
              onClick={() => handleAppStoreClick("white")}
            />
          </section>
        )}
      </section>
      {/* Wavy Divider */}
      <img src={waveImage} alt="wave" className="wavy-divider" />

      {/* Features Section */}
      <section className="features">
        <div className="feature-set">
          <div className="feature white-divider">
            <img src={personalizedIcon} alt="Personalized" />
            <h3>Personalized</h3>
            <p>
              Coaches adapt to your background, experiences, and needs, helping
              you connect the dots over time.
            </p>
          </div>
          <div className="feature white-divider">
            <img src={guidanceIcon} alt="Expert Guidance" />
            <h3>Expert guidance</h3>
            <p>
              Trained by a team of psychotherapists on tens of thousands of
              conversations, our AI coaches leverage clinically effective CBT
              techniques.
            </p>
          </div>

          <div className="feature white-divider">
            <img src={availableIcon} alt="Always Available" />
            <h3>Always available</h3>
            <p>
              BetterNow is available 24/7 to provide the support you need in the
              moment. No waiting, just instant help at your fingertips.
            </p>
          </div>

          <div className="feature">
            <img src={freeIcon} alt="100% Free" />
            <h3>100% free</h3>
            <p>
              Join during early access for free lifetime access to BetterNow.
              Share feedback to help us shape the product.
            </p>
          </div>
        </div>
        <section className="cta">
          <p>Download BetterNow today.</p>
          <img
            src={appStoreBadgeBlack}
            alt="Download on the App Store"
            className="app-store-badge"
            onClick={() => handleAppStoreClick("black")}
          />
        </section>
      </section>

      {/* Footer */}
      <footer>
        <a href="/about?landing_page=true" target="_blank">
          About
        </a>
        <a href="mailto:support@betternow.ai">Contact</a>
        <a href="/terms-and-conditions?landing_page=true" target="_blank">
          Terms
        </a>
        <a href="/privacy-policy?landing_page=true" target="_blank">
          Privacy
        </a>
      </footer>
    </div>
  );
};

export default LandingPage;
