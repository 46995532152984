import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import LandingPage from "./components/LandingPage";
import StaticPage from "./components/StaticPage";
import { NotFound } from "./components/NotFound";

const BASE_DOCS_URL =
  process.env.REACT_APP_DOCS_URL || "https://apps.persona-ai.ai/v1/api/";

const App = () => {
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID || "");
  }, []);
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route
        path="/privacy-policy"
        element={
          <StaticPage
            title="Privacy Policy"
            iframeURL={BASE_DOCS_URL + "privacy-policy"}
          />
        }
      />
      <Route
        path="/consumer-health-data-privacy-policy"
        element={
          <StaticPage
            title="Consumer Health Data Privacy Policy"
            iframeURL={BASE_DOCS_URL + "consumer-health-data-privacy-policy"}
          />
        }
      />
      <Route
        path="/about"
        element={
          <StaticPage title="About" iframeURL={BASE_DOCS_URL + "about"} />
        }
      />
      <Route
        path="/urgent-help"
        element={
          <StaticPage
            title="Urgent Help"
            iframeURL={BASE_DOCS_URL + "urgent-help"}
          />
        }
      />
      <Route
        path="/terms-and-conditions"
        element={
          <StaticPage
            title="Terms and Conditions"
            iframeURL={BASE_DOCS_URL + "terms-and-conditions"}
          />
        }
      />
      <Route
        path="/panic-help"
        element={
          <StaticPage
            title="Panic Help"
            iframeURL={BASE_DOCS_URL + "panic-help"}
          />
        }
      />
      <Route
        path="/alpha/:ad_source"
        element={
          <GeoRestrictedRedirect
            externalLink={
              process.env.REACT_APP_TYPEFORM_REDIRECT || "https://betternow.ai"
            }
          />
        }
      />
      <Route
        path="/alpha"
        element={
          <GeoRestrictedRedirect
            externalLink={
              process.env.REACT_APP_TYPEFORM_REDIRECT || "https://betternow.ai"
            }
          />
        }
      />
      <Route path="/signup/:ad_source" element={<SignupRedirect />} />
      <Route path="/signup" element={<SignupRedirect />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

const SignupRedirect = () => {
  const navigate = useNavigate();
  const { ad_source } = useParams();

  useEffect(() => {
    if (ad_source) {
      navigate(`/?ad_source=${ad_source}`);
    } else {
      navigate("/");
    }
  }, [ad_source, navigate]);

  return null;
};

const GeoRestrictedRedirect = ({ externalLink }: { externalLink: string }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isAllowed, setIsAllowed] = useState(true);
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const { ad_source } = useParams(); // Get ad_source from route params
  const urlParams = new URLSearchParams(window.location.search);
  const adSourceParam = ad_source || urlParams.get("ad_source") || "";

  useEffect(() => {
    const fetchGeoData = async () => {
      // Extract the ad_source parameter from the current URL

      try {
        const response = await fetch(
          `https://geo.ipify.org/api/v2/country?apiKey=${process.env.REACT_APP_GEOFY_API_KEY}`
        );
        const data = await response.json();
        const country = data.location?.country;

        if (country) {
          window.location.href = `${externalLink}#source=${country}&ad_source=${
            adSourceParam || "none"
          }`;
        } else {
          window.location.href = `${externalLink}#source=unknown&ad_source=${
            adSourceParam || "none"
          }`;
        }
      } catch (error) {
        console.error("Failed to fetch IP geolocation data:", error);
        // If there's an error or country is not determined, allow access
        window.location.href = `${externalLink}#source=geo-error&ad_source=${
          adSourceParam || "none"
        }`;
      } finally {
        setIsLoading(false); // End loading state
      }
    };

    fetchGeoData();
  }, [externalLink, adSourceParam]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/alpha",
      title: `Redirect - ${adSourceParam}`,
    });
  }, [adSourceParam]);

  if (isLoading) {
    return <p style={{ padding: "20px" }}>Checking your location...</p>; // Loading message
  }

  if (!isAllowed) {
    return <NotSupportedMessage />;
  }

  return <p style={{ padding: "20px" }}>Redirecting to external link...</p>;
};

const NotSupportedMessage = () => (
  <div style={{ padding: "20px" }}>
    <h2>Unfortunately, we are not supporting signups from your country now.</h2>
    <p>
      If you feel like this might be a mistake, contact us at{" "}
      <a href="mailto:support@betternow.ai">support@betternow.ai</a>.
    </p>
    <p>We are working towards a global launch soon, stay tuned!</p>
  </div>
);

export default App;
